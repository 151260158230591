import Mixpanel from 'mixpanel-browser';
import { UnleashAxios } from '../unleashAxios';
// TODO: Remove this machine import and use the real token
import { createStore } from '../../store';
import { MixpanelStore } from './mixpanel-store';

export const TRACK_EVENT_NAME = {
  BUTTON_CLICKED: 'BUTTON_CLICKED',
  PAGE_VIEWED: 'PAGE_VIEWED',
  BANNER_CLICKED: 'BANNER_CLICKED',
  TAB_CLICKED: 'TAB_CLICKED',
  CARD_CLICKED: 'CARD_CLICKED',
  CHECK_SELECTED: 'CHECK_SELECTED',
  CHECK_UNSELECTED: 'CHECK_UNSELECTED',
  LOADING_ERROR: 'LOADING_ERROR',
  MODAL_VIEWED: 'MODAL_VIEWED',
  FILE_DOWNLOADED: 'FILE_DOWNLOADED',
  ROW_CLICKED: 'ROW_CLICKED',
  CHECKBOX_CLICKED: 'CHECKBOX_CLICKED',
  TOAST_VIEWED: 'TOAST_VIEWED',
  ALERT_VIEWED: 'ALERT_VIEWED',
};

const MIXPANEL_NAME = 'mixpanel-walle';

export async function initialize(mixpanelToken) {
  const store = createStore();
  const unleash = UnleashAxios.create(store.app.env);
  const token = mixpanelToken || store?.app?.mixpanelToken || '';
  const isEnabled = await unleash.isEnabled(MIXPANEL_NAME);

  MixpanelStore.enabled = isEnabled;

  if (token && isEnabled) {
    Mixpanel.init(token, {
      debug: false,
      track_pageview: true,
      persistence: 'localStorage',
      ignore_dnt: true,
    });
  }
}

/**
 * Sends an event to Mixpanel with the specified properties.
 *
 * @param {Object} params - The parameters for the event.
 * @param {string} params.name - The name of the event.
 * @param {string} [params.identifierName=''] - The identifier name for the event.
 * @param {string} params.scope - The scope of the event.
 * @param {string} [params.pageName=''] - The name of the page where the event occurred.
 * @param {string} [params.entity=''] - The entity associated with the event.
 * @param {Object} [params.customProperties={}] - Custom properties to include with the event.
 * @returns {void}
 */
export function sendEvent({
  name,
  identifierName = '',
  scope,
  pageName = '',
  entity = '',
  customProperties = {},
}) {
  try {
    const store = createStore();
    const isEnabled = MixpanelStore.enabled;

    if (!isEnabled) {
      return;
    }

    const formattedCustomProperties = Object.keys(customProperties).reduce(
      (acc, key) => ({
        ...acc,
        [`$${key}`]: customProperties[key],
      }),
      {},
    );

    const properties = {
      $application: 'walle',
      $environment: store.app.env,
      $realm: store?.currentEntity?.type || undefined,
      $scope: scope,
      $identifierName: identifierName,
      $appRuntimePlatform: 'web',
      $pageName: pageName,
      $entity: entity,
      $eventCustomProperties: {
        // ...globalProperties.customProperties,
        ...formattedCustomProperties,
      },
    };

    return Mixpanel.track(name, properties);
  } catch (error) {
    console.error('Error sending event to Mixpanel', error);
  }
}

export function identifyUser({ userId }) {
  try {
    const isEnabled = MixpanelStore.enabled;

    if (!isEnabled) {
      return;
    }

    return Mixpanel.identify(userId);
  } catch (err) {
    console.error('Error identifying user in Mixpanel', err);
  }
}

export function setUserData({ name, email, plan, type }) {
  try {
    const isEnabled = MixpanelStore.enabled;

    if (!isEnabled) {
      return;
    }

    return Mixpanel.people.set({
      $name: name,
      $email: email,
      plan,
      type,
    });
  } catch (err) {
    console.error('Error setting user data in Mixpanel', err);
  }
}

/**
 * Sends an event to Mixpanel with the specified properties.
 *
 * @param {Object} params - The parameters for the event.
 * @param {string} [params.identifierName=''] - The identifier name for the event.
 * @param {string} params.scope - The scope of the event.
 * @param {string} [params.pageName=''] - The name of the page where the event occurred.
 * @param {string} [params.entity=''] - The entity associated with the event.
 * @param {Object} [params.customProperties={}] - Custom properties to include with the event.
 * @returns {void}
 */
export function trackPageView({
  identifierName,
  scope,
  pageName,
  entity,
  customProperties = {},
}) {
  return sendEvent({
    name: TRACK_EVENT_NAME.PAGE_VIEWED,
    identifierName,
    scope,
    pageName,
    entity,
    customProperties,
  });
}

export default {
  sendEvent,
  identifyUser,
  setUserData,
  trackPageView,
  initialize,
};
