/**
 * TODO: With the time, we will have a lot of identifiers, so, each module need to have a file with the identifiers that it uses.
 */
export const TRACK_EVENT_IDENTIFIER = {
  VIEW_COMMUNICATION: 'visualizar_comunicado',
  WRITE_COMMUNICATION: 'escrever_comunicado',
  SEND_COMMUNICATION: 'enviar_comunicado',
  FORWARD_MESSAGES: 'encaminhar_mensagens',
  DISABLE_ENTITY: 'desativar_entidade',
};
